import React, { Fragment } from "react";

import Seo from "../../components/seo";

const NotFoundPage = () => (
  <Fragment>
    <Seo title="404: Not found" />
    <h1>404</h1>
  </Fragment>
);

export default NotFoundPage;
